import { template as template_e46b5c6f99f7431ebc892e5c281e9673 } from "@ember/template-compiler";
const FKLabel = template_e46b5c6f99f7431ebc892e5c281e9673(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

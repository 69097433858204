import { template as template_f790d3e26e624a92ba83f572ecf695e6 } from "@ember/template-compiler";
const WelcomeHeader = template_f790d3e26e624a92ba83f572ecf695e6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

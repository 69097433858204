import { template as template_f95eb3a0b00c4c329f560ae67049a161 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f95eb3a0b00c4c329f560ae67049a161(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;

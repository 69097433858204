import { template as template_6e942dbf52434a32ab7c26f47e38cb25 } from "@ember/template-compiler";
const FKText = template_6e942dbf52434a32ab7c26f47e38cb25(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
